import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import '../fragments';

import { localize } from '../utils';
import { DEFAULT_LANG } from '../constants';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const ContactsPage = ({ data, lang }) => {
  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }

  const site = localize(data.sanitySite, langs);

  useEffect(() => {
    function initMap() {
      const coordinates = new window.google.maps.LatLng(site.location.lat, site.location.lng);
      const mapOptions = {
        zoom: 15,
        center: coordinates,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      };
      const map = new window.google.maps.Map(document.getElementById('map-canvas'), mapOptions);
      const marker = new window.google.maps.Marker({
        map: map,
        position: coordinates,
      });
      const infowindow = new window.google.maps.InfoWindow({
        content: site.address,
      });
      window.google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
      });
      infowindow.open(map, marker);
    }
    const initMapInterval = setInterval(() => {
      if (window.google) {
        clearInterval(initMapInterval);
        initMap();
      }
    }, 100);
  }, []);

  return (
    <Layout>
      <SEO langs={langs} />
      <div className="contacts">
        <h3>Contactos</h3>
        {site.contacts.map((contact, i) => (
          <p key={i}>
            <label>{contact.name}</label>:{' '}
            <span className="contact">
              <a href={contact.url}>{contact.value}</a>
            </span>
          </p>
        ))}
        <p>
          <label>Morada</label>:
        </p>
        <div id="map-canvas"></div>
      </div>
    </Layout>
  );
};

ContactsPage.defaultProps = {
  lang: DEFAULT_LANG,
};

ContactsPage.propTypes = {
  lang: PropTypes.string,
};

export const query = graphql`
  query {
    sanitySite {
      contacts {
        name
        value
        url
      }
      address
      location {
        lat
        lng
      }
    }
  }
`;

export default ContactsPage;
